import * as React from "react"
import { Link } from "gatsby"
import ActionBtn from "../components/action-btn"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ScrollBtn from "../components/scroll-btn"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"

import { graphql } from "gatsby"
import { rewriteSlug } from "../utils/rewriteSlug"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const ProjectsPage = props => {
  const {
    IntroText,
    LoadMoreButtonText,
    NumberOfProjectsShown,
    Title,
    slug,
    locale,
  } = props.data.strapiProjectsPage

  const projects = props.data.allStrapiProject.edges
  const [items, setItems] = React.useState(4)
  const [step, setStep] = React.useState(4)
  const [projectsArray, setProjectsArray] = React.useState([])

  const loadMoreProjects = () => {
    setItems(items + step)
  }

  useEffect(() => {
    let number

    if (NumberOfProjectsShown) {
      number = +NumberOfProjectsShown.split(" ")[1]

      if (number) {
        setItems(number)
        setStep(number)
      }
    }
  }, [setItems, NumberOfProjectsShown])

  useEffect(() => {
    setProjectsArray(projects.slice(0, items))
  }, [items, projects])

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={
          props.data.strapiProjectsPage.localizations.data[0].attributes
        }
        contactSlug={props.data.strapiContact.slug}
        currentPage={props.data.strapiProjectsPage.slug}
        locale={locale}
      />
      <Layout>
        <Seo
          lang={locale}
          description={IntroText.data.IntroText}
          title={Title}
        />
        <section className="single-page__hero flex flex-col justify-center lg:min-h-[720px] px-6 relative">
          <div className="text-center my-8">
            <div>
              <div className="hero-circles-container">
                <div className="circle circle--dashed circle--1 circle--1-dashed"></div>
                <div className="circle circle--dashed circle--2 circle--2-dashed"></div>
                <div className="circle circle--dashed circle--3 circle--3-dashed"></div>
                <div className="circle circle--dashed circle--4 circle--4-dashed"></div>
              </div>
            </div>
            <h1 className="single-page__hero-title">{Title}</h1>

            <div className="single-page__hero-text">
              <ReactMarkdown>{IntroText.data.IntroText}</ReactMarkdown>
            </div>
          </div>
          <div className="scrollButtonContainer">
            <ScrollBtn
              beforeText={
                props.data.strapiGeneralTranslation.ScrollDownOrJustUse
              }
              afterText={props.data.strapiGeneralTranslation.Keys}
            />
          </div>
        </section>

        <div className="projects-list">
          {projectsArray.map((project, index) => {
            const data = project.node

            return (
              <section key={index}>
                <Link
                  to={"/" + rewriteSlug(data.slug, slug)}
                  id={"projects-list__item-" + index}
                  className="projects-list__item"
                >
                  <style>
                    {`#projects-list__item-${index} {
                    --project-color: ${data.Color};
                    --project-shadow-color-1: ${data.Color}1C;
                    --project-shadow-color-2: ${data.Color}14;
                    --project-shadow-color-3: ${data.Color}10;
                    --project-shadow-color-4: ${data.Color}0E;
                    --project-shadow-color-5: ${data.Color}0B;
                    --project-shadow-color-6: ${data.Color}07;
                  }`}
                  </style>
                  <div className="projects-list__item-image-wrapper">
                    <div className="projects-list__item-overlay">
                      <ActionBtn
                        text={props.data.strapiGeneralTranslation.ViewProject}
                        isWhite={true}
                      />
                    </div>
                    <img
                      className="projects-list__item-image"
                      src={data.IntroImage.url}
                      alt={data.IntroImage.alternativeText}
                      loading="lazy"
                    />
                  </div>
                  <p className="projects-list__item-name">{data.ProjectName}</p>
                  {data.ThumbnailTitle?.data ? (
                    <div className="projects-list__item-title">
                      <ReactMarkdown>
                        {data.ThumbnailTitle.data.ThumbnailTitle}
                      </ReactMarkdown>
                    </div>
                  ) : (
                    <h2 className="projects-list__item-title">{data.Title}</h2>
                  )}

                  {data.Services && (
                    <p className="projects-list__item-services">
                      {data.Services}
                    </p>
                  )}
                </Link>
              </section>
            )
          })}
        </div>
        {items < projects.length && (
          <div className="projects-load-more-wrapper">
            <button
              className="inline-block font-headline font-extrabold text-base lg:text-xl uppercase no-underline rounded-full bg-gradient-to-r from-maroon to-orange-red text-white lg:m-0 py-4 px-7 hover:from-shiraz hover:to-shiraz"
              to="/contact-us"
              onClick={loadMoreProjects}
            >
              {LoadMoreButtonText}
            </button>
          </div>
        )}
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        cookieSettingsText={props.data.strapiGeneralTranslation.CookieSettings}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        locale={locale}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const query = graphql`
  query ($slug: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiProjectsPage(slug: { eq: $slug }, locale: { eq: $locale }) {
      slug
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      IntroText {
        data {
          IntroText
        }
      }
      LoadMoreButtonText
      NumberOfProjectsShown
      Title
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    allStrapiProject(
      sort: { fields: createdAt, order: DESC }
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          ClientIntroText {
            data {
              ClientIntroText
            }
          }
          ClientIntroTitle
          Color
          Content {
            data {
              Content
            }
          }

          IntroImage {
            url
            alternativeText
          }
          OutcomeText {
            data {
              OutcomeText
            }
          }
          OutcomeTitle
          ProjectName
          slug
          Text {
            data {
              Text
            }
          }
          Title
          ViewWebsiteLink
          ViewWebsiteText
          WhatWeDidList {
            Text
          }
          WhatWeDidTitle
          ThumbnailTitle {
            data {
              ThumbnailTitle
            }
          }
          Services
        }
      }
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }

    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }
    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }
  }
`

export default ProjectsPage
